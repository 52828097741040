import React, {
  Fragment,
  useState
} from 'react'  
import update from 'immutability-helper'
import {
  Card,
  TextField,
  Popover,
  InlineError,
  ColorPicker,
  FormLayout,
  RangeSlider,
  LegacyStack,
} from "@shopify/polaris"
import { throttle } from 'lodash'
import FieldTitle from '../../../../../components/fieldTitle'
function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}
function BodyTab(props) {
  const { shop, state, setState, productPickerShow, error } = props;
  const [offerBackgroundColorPopoverVisible, setOfferBackgroundColorPopoverVisible] = useState(false)
  const [offerBackgroundColorHsl, setOfferBackgroundColorHsl] = useState({
      hue: 0,
      saturation: 0,
      brightness: 100,
      alpha: 1,
    })
  const [titleColorPopoverVisible, setTitleColorPopoverVisible] = useState(false)
  const [titleColorHsl, setTitleColorHsl] = useState({
        hue: 0,
        saturation: 0,
        brightness: 0,
        alpha: 1,
      })
  const [descriptionColorPopoverVisible, setDescriptionColorPopoverVisible] = useState(false)
  const [descriptionColorHsl, setDescriptionColorHsl] = useState({
            hue: 0,
            saturation: 0,
            brightness: 0,
            alpha: 1,
          })
  const offerBackgroundColorActivatorButton = (
      <TextField 
        label= {<FieldTitle title="Background color"/>}
        onFocus={() => setOfferBackgroundColorPopoverVisible(true)} 
        value={state.bodyBackgroundColor}
        onChange={(val) => setState(update(state, { bodyBackgroundColor: { $set: val }})) }
        connectedLeft={
          <div 
            style={{ 
              borderRadius: 5,
              cursor: 'pointer',
              height: 36, 
              width: 40, 
              marginRight: 8,
              backgroundColor: state.bodyBackgroundColor, 
              border: state.bodyBackgroundColor.toLowerCase() === '#ffffff' && '1px solid #000000'
            }}
            onClick={() => setOfferBackgroundColorPopoverVisible(true)} 
          />
        }
      />
    )
    const onOfferBackgroundColorChange = (updatedColor) => {
      const color = {
        hue: updatedColor.hue || offerBackgroundColorHsl.hue || 0,
        saturation: updatedColor.saturation || offerBackgroundColorHsl.saturation || 0,
        brightness: updatedColor.brightness || offerBackgroundColorHsl.brightness || 0,
        alpha: 1,
      }
      const hex = hslToHex(
        color.hue, 
        color.saturation * 100, 
        color.brightness * 100,
      )
  
      console.log('Color changed: ', color, hex)
  
      setOfferBackgroundColorHsl(color)
      setState(update(state, { bodyBackgroundColor: { $set: hex }})) 
    }
  const onOfferBackgroundColorChangeThrottled = throttle(onOfferBackgroundColorChange, 250)

  const onTitleColorChange = (updatedColor) => {
      const color = {
        hue: updatedColor.hue || offerBackgroundColorHsl.hue || 0,
        saturation: updatedColor.saturation || offerBackgroundColorHsl.saturation || 0,
        brightness: updatedColor.brightness || offerBackgroundColorHsl.brightness || 0,
        alpha: 1,
      }
      const hex = hslToHex(
        color.hue, 
        color.saturation * 100, 
        color.brightness * 100,
      )
  
      setTitleColorHsl(color)
      setState(update(state, { titleColor: { $set: hex }})) 
    }
  const onTitleColorChangeThrottled = throttle(onTitleColorChange, 250)
  const onDescriptionColorChange = (updatedColor) => {
      const color = {
        hue: updatedColor.hue || offerBackgroundColorHsl.hue || 0,
        saturation: updatedColor.saturation || offerBackgroundColorHsl.saturation || 0,
        brightness: updatedColor.brightness || offerBackgroundColorHsl.brightness || 0,
        alpha: 1,
      }
      const hex = hslToHex(
        color.hue, 
        color.saturation * 100, 
        color.brightness * 100,
      )
  
      setDescriptionColorHsl(color)
      setState(update(state, { descriptionColor: { $set: hex }})) 
    }
  const onDescriptionColorChangeThrottled = throttle(onDescriptionColorChange, 250)
  const titleColorActivatorButton = (
    <TextField 
      label= {<FieldTitle title="Product title color"/>}
      onFocus={() => setTitleColorPopoverVisible(true)} 
      value={state.titleColor}
      onChange={(val) => setState(update(state, { titleColor: { $set: val }})) }
      connectedLeft={
        <div 
          style={{ 
            borderRadius: 5,
            cursor: 'pointer',
            height: 36, 
            width: 40,
            marginRight: 8,
            backgroundColor: state.titleColor,
            border: state.titleColor.toLowerCase() === '#ffffff' && '1px solid #000000'
          }}
          onClick={() => setTitleColorPopoverVisible(true)} 
        />
      }
    /> 
  )
  const descriptionColorActivatorButton = (
    <TextField 
      label={<FieldTitle title="Marketing message color" />}
      onFocus={() => setDescriptionColorPopoverVisible(true)} 
      value={state.descriptionColor}
      onChange={(val) => setState(update(state, { descriptionColor: { $set: val }})) }
      connectedLeft={
        <div 
          style={{ 
            borderRadius: 5,
            cursor: 'pointer',
            height: 36, 
            width: 40,
            marginRight: 8,
            backgroundColor: state.descriptionColor,
            border: state.descriptionColor.toLowerCase() === '#ffffff' && '1px solid #000000'
          }}
          onClick={() => setDescriptionColorPopoverVisible(true)} 
        />
      }
    />
  )
  const suffixStyles = {
      minWidth: '24px',
      textAlign: 'right',
    };
  return (
            <FormLayout>
              <RangeSlider
              label={<FieldTitle title="Border radius" />}
              prefix={<p>px</p>}
              suffix={<p style={suffixStyles}>{state.popupBorderRadius}</p>}
              value={state.popupBorderRadius}
              onChange={(val) => setState(update(state, { popupBorderRadius: { $set: val }}))}
              output
              />
              <Popover
                active={titleColorPopoverVisible}
                activator={titleColorActivatorButton}
                onClose={() => setTitleColorPopoverVisible(false)}
                sectioned
              >
                <ColorPicker 
                  color={titleColorHsl}
                  onChange={onTitleColorChangeThrottled}
                />
              </Popover>
              <Popover
                active={descriptionColorPopoverVisible}
                activator={descriptionColorActivatorButton}
                onClose={() => setDescriptionColorPopoverVisible(false)}
                sectioned
              >
                <ColorPicker 
                  color={descriptionColorHsl}
                  onChange={onDescriptionColorChangeThrottled}
                />
              </Popover>
              <Popover
                active={offerBackgroundColorPopoverVisible}
                activator={offerBackgroundColorActivatorButton}
                onClose={() => setOfferBackgroundColorPopoverVisible(false)}
                >
                <ColorPicker 
                  color={offerBackgroundColorHsl}
                  onChange={onOfferBackgroundColorChangeThrottled}
                />
              </Popover>
            </FormLayout>
  )
}

export default BodyTab;